import { duotone, light, regular, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Input, List, Modal, Slider, Tooltip, Typography } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "src/@components/Avatar";
import Label from "src/@components/Label";
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Textarea from "src/@components/form/Textarea";
import PageHeader from "src/@components/page/PageHeader";
import { supabase } from "src/supabase";
import logo from "src/assets/universus-black.png"
import { useProfile } from "src/profiles/ProfilesApi";
import clsx from "clsx";
import Form from "src/@components/form/Form";
import FoldersForm, { FoldersFormInitialValues } from "src/folders/FoldersForm";
import { useFolder, useRemoveFolder, useUpdateFolder } from "src/folders/FoldersApi";
import { useSettings } from "src/settings/SettingsApi";
import { useQueryClient } from "@tanstack/react-query";
// import WebViewer from '@pdftron/pdfjs-express-viewer';
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { useUI } from "src/layout/UI";
import { useAuth } from "src/auth/Auth";

const { APP_API_URL, APP_PSPDFKIT_URL } = import.meta.env

export default function Files({ folder, fetchFiles, files }) {
  const [search, setSearch] = useState('')
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: update } = useUpdateFolder({ queryParams: {} })
  const openFile = (file) => [setFile(file)]
  const closeFile = () => [setFile(null)]

  const renderItem = (item, view) => <div style={{ display: 'flex', flexDirection: 'row', gap: 16, alignItems: 'center', userSelect: 'none' }}>
    <FontAwesomeIcon style={{ position: 'relative', bottom: view ? 8 : 0 }} icon={getFontAwesomeIconForMimeType(item.metadata.mimetype)} size="lg" />
    <div className="flex flex-col">
      {item.name}
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>{formatBytes(item.metadata.size)}</Typography.Text>
    </div>
  </div>

  const handleFileChange = async ({ target: { files } }) => {
    if (files.length) {
      setIsLoading(true)

      await update({ id: folder.id, folders: [{ id: folder.id, files: Array.from(files) }] }, { onSuccess: () => [fetchFiles(true), setIsLoading(false)], onError: () => setIsLoading(false) })
    }
  }

  const datas = useMemo(() => {
    return !files ? [] : files.filter(file => file.name.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .includes(
        search
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      ))
  }, [search, files])

  return <>
    <input multiple ref={fileInputRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
    <Viewer folder={folder} file={file} closeFile={closeFile} renderItem={renderItem} />
    <List
      loading={!files}
      style={{
        height: '100%',
        maxHeight: "100%",
        overflow: "auto",
        width: "100%",
        borderRadius: "0px",
        border: "none",
      }}
      className={clsx('scroll universus-chatbot', !files && "isLoading")}
      bordered
      dataSource={datas}
      header={
        <div className='bg-base flex items-center justify-between' style={{
          position: "sticky",
          top: "0px",
          left: "0px",
          width: "100%"
        }}>
          <Input className="flex-grow" value={search} onChange={(e) => setSearch(e.target.value)} autoFocus variant="borderless" placeholder="Rechercher un document" />
          <Tooltip placement="left" title="Demander un document"><Button shape="circle" type="text" icon={<FontAwesomeIcon icon={light('hand')} />} /></Tooltip>
          <Tooltip placement="left" title="Ajouter un document"><Button shape="circle" type="text" icon={<FontAwesomeIcon icon={light('plus')} />} onClick={() => fileInputRef.current.click()} loading={isLoading} /></Tooltip>
        </div>}
      renderItem={(item) => (
        <List.Item onClick={() => openFile(item)} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}>
          {renderItem(item)}
        </List.Item>
      )}
    />
  </>
}

export function PDFViewer({ uri, report }) {
  const pdfViewer = useRef(null);
  const [loading, setLoading] = useState(true);
  const [jwt, setJwt] = useState(null)
  const { darkMode } = useUI()
  const icon = uri && uri.toLowerCase().includes('.pdf') ? solid('file-pdf') : solid('file-doc')
  const { session } = useAuth()

  useEffect(() => {
    if (false && report && !jwt) {
      const getpPspdfkitJWT = async () => {
        const { data, error } = await fetch(`${APP_API_URL}/pspdfkit/${report}`, {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        }).then(res => res.json())

        setJwt(data.token)
      }

      getpPspdfkitJWT()
    } else if (uri) {
      const container = pdfViewer.current; // This `useRef` instance will render the PDF.

      let PSPDFKit, instance;

      (async function () {
        try {
          PSPDFKit = await import("pspdfkit")

          PSPDFKit.unload(container)

          instance = await PSPDFKit.load({
            toolbarPlacement: PSPDFKit.ToolbarPlacement.BOTTOM,
            theme: PSPDFKit.Theme[darkMode ? 'DARK' : 'LIGHT'],
            styleSheets: [
              "/pspdfkit-lib.css",
            ],
            container,
            renderPageCallback: () => {
              if (loading) {
                setLoading(false)
              }
            },
            baseUrl: `${window.location.protocol}//${window.location.host}/`,
            document: uri,
          });

          if (report) {
            instance.setViewState(viewState => viewState.set("showToolbar", false));
          } else {
            const items = instance.toolbarItems;
            instance.setToolbarItems(items.filter((item) => ['pager', 'pan', 'zoom-in', 'zoom-out', 'zoom-mode', 'search'].includes(item.type)));
          }
        } catch (error) {
          console.log(error)
        }
      })();

      return () => PSPDFKit && PSPDFKit.unload(container)
    }
  }, [uri, jwt, report]);

  return <div className="relative h-full w-full">
    {(loading) && <div className="absolute top-0 left-0 flex flex-col h-full grow gap-4 z-20 items-center justify-center w-full">
      <Typography.Text type="secondary" className="relative">
        <FontAwesomeIcon icon={icon} size={"5x"} />
        <FontAwesomeIcon
          style={{ right: -24, bottom: 0 }}
          className="fa-spinner text-primary absolute opacity-60"
          icon={solid('spinner')}
          size="lg"
        />
      </Typography.Text>
      <Typography.Text type="secondary">
        <strong>Chargement du {report ? 'rapport' : 'document'}...</strong>
      </Typography.Text>
    </div>}

    <div ref={pdfViewer} className="relative h-full w-full" style={{ opacity: loading ? 0 : 1 }}></div>
  </div>
}

function Player({ uri }) {
  return <div className="flex justify-center p-4">
    <audio autoPlay src={uri} controls />
  </div>
}

function ImageViewer({ uri }) {
  const { zoomIn, zoomOut, resetTransform } = useControls()

  return <div className="relative" style={{ maxHeight: '100%' }}>
    <div className="flex items-center gap-2 absolute" style={{ bottom: 10, right: 10, zIndex: 4 }}>
      <Button type="default" shape="circle" icon={<FontAwesomeIcon icon={light('plus')} />} onClick={() => zoomIn()}></Button>
      <Button type="default" shape="circle" icon={<FontAwesomeIcon icon={light('minus')} />} onClick={() => zoomOut()}></Button>
    </div>
    <TransformComponent>
      <img src={uri} className="w-full" />
    </TransformComponent>
  </div>
}

function TextViewer({ uri }) {
  const [text, setText] = useState('')

  useEffect(() => {
    if (uri) {
      fetch(uri).then(response => response.text()).then(text => setText(text))
    }
  }, [uri])

  return <div className="p-4 h-full box-border">
    <Textarea style={{ height: '100%', fontFamily: 'Neon' }} readOnly className="h-full scroll" value={text} />
  </div>
}

function Viewer({ file, closeFile, renderItem, folder }) {
  const [uri, setUri] = useState(false)

  useEffect(() => {
    if (file) {
      setUri(false)
      const fetchFile = async () => {
        const {
          data: { signedUrl },
        } = await supabase.storage.from('folders').createSignedUrl(`${folder.company.id}/${folder.folder_id}/${file.name}`, 60)

        setUri(signedUrl)
      }

      fetchFile()
    }
  }, [file])

  let element
  let type

  if (uri && file) {
    if (
      file.metadata.mimetype.includes('pdf')
      || file.metadata.mimetype.includes('office')
      || file.metadata.mimetype.includes('xlsx')
      || file.metadata.mimetype.includes('docx')
    ) {
      type = 'pdf'
      element = <PDFViewer uri={uri} />
    } else if (file.metadata.mimetype.includes('image')) {
      type = 'image'
      element = <div className="w-full h-full flex justify-center items-center p-4 box-border">
        <TransformWrapper
          initialScale={1}
        >
          <ImageViewer uri={uri} />
        </TransformWrapper>
      </div >
    } else if (file.metadata.mimetype.includes('audio')) {
      type = 'audio'
      element = <Player uri={uri} />
    } else if (file.metadata.mimetype.includes('video')) {
      type = 'video'
      element = <div className="p-4"><video autoPlay src={uri} controls></video></div>
    } else if (file.metadata.mimetype.includes('text')) {
      type = 'text'
      element = <TextViewer uri={uri} />
    } else {
      type = 'download'
      element = <div className="flex items-center p-4">
        <Button block onClick={() => window.open(uri, '_blank')} icon={<FontAwesomeIcon icon={solid("cloud-arrow-down")} />} size="large">Télécharger</Button>
      </div>
    }
  }

  const isFull = true

  return <div>
    {file && <Modal className={clsx("modal-file", isFull && "is-full")} footer={null} open={file} onCancel={closeFile} getContainer={() => document.getElementById("modalMount")}>
      {<div className="flex flex-col h-full">
        <div className={clsx("w-full box-border", "border-0 border-b border-solid border-base")} style={{ padding: '10px 16px 10px 16px' }}>
          {renderItem(file, true)}
        </div>
        <div className={clsx("flex relative", isFull && "flex-grow")}>
          <div className={clsx("w-full box-border", isFull && 'h-full absolute')}>
            {uri && element}
            {/* {uri && <DocViewer documents={[{ uri }]} pluginRenderers={DocViewerRenderers} />} */}
          </div>
        </div>
      </div>}
    </Modal>}
  </div>
}

const iconsMime = {
  'image': 'image',
  'audio': 'audio',
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getFontAwesomeIconForMimeType(mimeType) {
  if (mimeType.includes('audio')) {
    return light('volume')
  }

  if (mimeType.includes('image')) {
    return light('image')
  }

  if (mimeType.includes('video')) {
    return light('video')
  }

  const iconMap = {
    'application/pdf': light('file-pdf'),
    'application/msword': light('file-word'),
    'application/vnd.ms-word': light('file-word'),
    'application/vnd.oasis.opendocument.text': light('file-word'),
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': light('file-word'),
    'application/vnd.ms-excel': light('file-excel'),
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': light('file-excel'),
    'application/vnd.oasis.opendocument.spreadsheet': light('file-excel'),
    'application/vnd.ms-powerpoint': light('file-powerpoint'),
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': light('file-powerpoint'),
    'application/vnd.oasis.opendocument.presentation': light('file-powerpoint'),
    'text/plain': light('file-alt'),
    'text/html': light('file-code'),
    'text/javascript': light('file-code'),
    'text/css': light('file-code'),
    'text/xml': light('file-code'),
    'application/zip': light('file-archive'),
    'application/x-rar-compressed': light('file-archive'),
    'application/x-msdownload': light('file-download'), // for .exe files
    'application/json': light('file-code'),
    'application/octet-stream': light('file'), // general binary file
    'application/x-7z-compressed': light('file-archive'),
    'multipart/form-data': light('folder')
  };

  return iconMap[mimeType] || light('file'); // default to generic file icon if unknown
}

const fetcher = (fontFileName) =>
  fetch(`/fonts-report/${fontFileName}`).then((r) => {
    if (r.status === 200) {
      return r.blob();
    } else {
      throw new Error();
    }
  });

let customFonts

let getCustomFonts = (PSPDFKit) => {
  if (customFonts) {
    return customFonts
  }

  customFonts = [
    "TypeType - TT Norms Pro Bold Italic.ttf",
    "TypeType - TT Norms Pro Bold.ttf",
    "TypeType - TT Norms Pro Italic.ttf",
    "TypeType - TT Norms Pro Regular.ttf"
  ].map(
    (font) => new PSPDFKit.Font({ name: font, callback: fetcher })
  );

  return customFonts
}
